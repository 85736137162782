<template>
  <v-row>
    <v-col cols="12" md="3" class="d-flex">
      <div class="align-self-center line-height-1-2 pr-lg-9 ">
        <h4 class="mb-2">Multikeywords:</h4>
        <span class="text--secondary">You can add up to {{maxAmount}} keywords to send additional queries.</span>
      </div>
    </v-col>

    <v-col cols="12" md="6" class="d-flex">
      <div class="custom--border pa-3 rounded h-100 w-100">
        <div class="mb-n2" v-if="multipleKeywords.length">
          <v-chip
              small
              v-for="multipleKeyword in multipleKeywords"
              :key="multipleKeyword"
              close
              close-icon="mdi-close-circle"
              label
              class="mr-2 mb-2"
              @click:close="removeKeyword(multipleKeyword)"
          >{{multipleKeyword}}</v-chip>
        </div>
        <span class="text--disabled" v-else>No keywords were added. Fill form to add your first keyword.</span>
      </div>
    </v-col>

    <v-col cols="12" md="3" class="d-flex">
      <div class="pl-lg-9  align-self-center w-100">
        <v-text-field
            v-model.trim="keyword"
            outlined
            persistent-placeholder
            placeholder="Enter Keyword"
            dense
            small
            flat
            required
            hide-details="auto"
            :error-messages="keywordError"
            @keypress="handleAddKeywordsKeyPress"
            class="font-size-15 mb-3"
            @input="keyword = keyword.toLowerCase()"
        >
        </v-text-field>
        <v-btn block color="cyan accent-4" class="custom--btn" outlined depressed height="40" @click="addNewKeyword">
          Add
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import {mapMutations, mapGetters} from 'vuex';
  import authApi from '../api/auth';
  import Label from "@/components/Label";
  import {showNotification} from "@/js/helpers";

  export default {
    name: 'MultipleKeywordsTable',

    props: {
      campaign: {
        type: Object,
        default: {}
      },
      onMultipleKeywordsChanged: {
        type: Function,
        required: true
      }
    },

    components: { Label },

    computed: {
      multipleKeywords() {
        return this.campaign.multipleKeywords || [];
      }
    },

    beforeMount() {

    },

    watch: {
      keyword() {
        if (this.keyword.length === 0) { this.keywordError = '' }
      }
    },

    methods: {
      removeKeyword(keyword) {
        const newMultipleKeywords = this.multipleKeywords.filter(_keyword => _keyword !== keyword);
        this.onMultipleKeywordsChanged(newMultipleKeywords);
      },
      addNewKeyword() {
        if (this.keyword === '') { return this.keywordError = 'This field is required.' }

        const keywordToAdd = this.keyword;

        const isKeywordAlreadyAdded = this.multipleKeywords.indexOf(keywordToAdd) !== -1;
        if (isKeywordAlreadyAdded) { return this.keywordError = 'This keyword is already added.' }

        const newMultipleKeywords = [...this.multipleKeywords, keywordToAdd];
        if (newMultipleKeywords.length > this.maxAmount) {
          return this.keywordError = `You can add only ${this.maxAmount} keywords`;
        }

        this.keyword = '';
        this.keywordError = '';

        this.onMultipleKeywordsChanged(newMultipleKeywords);
      },
      handleAddKeywordsKeyPress(e) {
        if (e.charCode === 13) {
          e.preventDefault();
          this.addNewKeyword();
        }
      },
    },

    data: () => ({
      keyword: '',
      keywordError: '',
      maxAmount: 5
    }),
  }
</script>

<style lang="scss">
  .input-error-message-no-gutters {
    .v-text-field__details {
      margin-bottom: 0 !important;
    }
  }

  .line-height-1-2 {
    line-height: 1.2;
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100%;
  }
</style>
