<template>
  <v-container fluid class="px-8">
    <v-row align="center" class="mb-3 mb-md-0">
      <v-col cols="12" sm="auto" >
        <h3>Edit Campaign</h3>
      </v-col>

      <v-col cols="12" sm="auto" class="ml-auto">
        <v-btn class="custom--btn" exact color="white" block light depressed small to="/rotator">
          <v-icon x-small left>
            mdi-arrow-left
          </v-icon>
          Back To Rotator
        </v-btn>
      </v-col>
    </v-row>

    <v-form v-model="valid" ref="form" v-if="campaign">
      <v-row >
        <v-col cols="12" lg="9">
          <v-card outlined class="pa-5 fill-height">
            <v-row class="custom--gutters">

              <v-col cols="12" md="6">
                <Label forInput="campaignName-input">Campaign Name:</Label>
                <v-text-field
                    v-model.trim="campaign.name"
                    @change="updateCampaign"
                    persistent-placeholder
                    placeholder="Enter Name"
                    outlined
                    dense
                    solo
                    flat
                    :rules="requiredRules"
                    hide-details="auto"
                    id="campaignName-input"
                    class="font-size-15"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <Label forInput="campaignMarket-select">Campaign Marketplace:</Label>
                <v-select
                    v-if="campaign.type !== 'walmartViaBrand' && campaign.type !== 'walmartViaSeller' && campaign.type !== 'walmartDirect'"
                    :items="marketplaceOptions"
                    v-model="campaign.market"
                    persistent-placeholder
                    @change="updateCampaign"
                    outlined
                    dense
                    flat
                    hide-details="auto"
                    id="campaignMarket-select"
                    class="font-size-15"
                    :item-disabled="disableMarketplaceOption"
                ></v-select>

                <WalmartMarketplaceOption v-else />

              </v-col>

              <v-col cols="12" v-if="settings.showAsinFieldOnEdit"
                     md="6"
              >
                <Label forInput="campaignAsin-input">Campaign ASIN:</Label>
                <v-text-field
                    v-model.trim="campaign.asin"
                    outlined
                    placeholder="Enter ASIN"
                    persistent-placeholder
                    @change="updateCampaign"
                    dense
                    flat
                    :rules="settings.requiredAsinOnEdit ? [...requiredRules, ...asinRules] : []"
                    hide-details="auto"
                    id="campaignAsin-input"
                    class="font-size-15"
                ></v-text-field>
              </v-col>

              <v-col cols="12" :md="campaign.type === 'buyTogether' ? '12' : '6'">
                <Label forInput="campaignDomain-select">Custom Domain:</Label>
                <v-select
                    :items="verifiedDomainsAndDefault"
                    v-model="campaign.customDomain"
                    persistent-placeholder
                    @change="updateCampaign"
                    outlined
                    dense
                    flat
                    hide-details="auto"
                    id="campaignDomain-select"
                    class="font-size-15"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6" v-if="campaign.type === 'alexaAmazonChoice' || campaign.type === 'alexaBestSeller' || campaign.type === 'viaBrand'">
                <Label forInput="campaignKeyword-input">
                  {{ campaign.type === 'viaBrand' ? 'Keywords:' : 'Keyword:' }}
                </Label>
                <v-text-field
                    v-model.trim="campaign.data.keyword"
                    outlined
                    persistent-placeholder
                    :placeholder="campaign.type === 'viaBrand' ? 'Enter Keywords' : 'Enter Keyword'"
                    dense
                    @change="updateCampaign"
                    flat
                    :rules="requiredRules"
                    hide-details="auto"
                    id="campaignKeyword-input"
                    class="font-size-15"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" v-if="campaign.type === 'viaBrand' || campaign.type === 'walmartViaBrand'">
                <Label forInput="campaignBrand-input">Brand:</Label>
                <v-text-field
                    v-model.trim="campaign.data.brand"
                    outlined
                    persistent-placeholder
                    placeholder="Enter Brand"
                    dense
                    @change="updateCampaign"
                    flat
                    :rules="requiredRules"
                    hide-details="auto"
                    id="campaignBrand-input"
                    class="font-size-15"
                ></v-text-field>
              </v-col>

              <template v-if="campaign.type === 'viaBrand'">
                <v-col cols="12" md="6" >
                  <Label forInput="campaignMinPrice-input">Minimum Price:</Label>
                  <v-text-field
                      v-model.number="campaign.data.minPrice"
                      outlined
                      persistent-placeholder
                      placeholder="Enter Min Price"
                      dense
                      flat
                      @change="updateCampaign"
                      hide-details="auto"
                      class="hide-number-controls font-size-15"
                      type="number"
                      id="campaignMinPrice-input"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" >
                  <Label forInput="campaignMaxPrice-input">Maximum Price:</Label>
                  <v-text-field
                      v-model.number="campaign.data.maxPrice"
                      outlined
                      persistent-placeholder
                      placeholder="Enter Max Price"
                      dense
                      @change="updateCampaign"
                      flat
                      hide-details="auto"
                      class="hide-number-controls font-size-15"
                      type="number"
                      id="campaignMaxPrice-input"
                  ></v-text-field>
                </v-col>
              </template>

              <template v-if="campaign.type === 'buyTogether'">
                <v-col cols="12" md="6" >
                  <Label forInput="campaignAsinOne-input">ASIN 1:</Label>
                  <v-text-field
                      v-model.trim="campaign.data.asinOne"
                      outlined
                      persistent-placeholder
                      placeholder="Enter ASIN 1"
                      dense
                      @change="updateCampaign"
                      flat
                      :rules="[...requiredRules, ...asinRules]"
                      hide-details="auto"
                      class="font-size-15"
                      id="campaignAsinOne-input"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" >
                  <Label forInput="campaignQuantityOne-input">Quantity 1:</Label>
                  <v-text-field
                      v-model.number="campaign.data.quantityOne"
                      outlined
                      persistent-placeholder
                      placeholder="Enter Quantity 1"
                      dense
                      flat
                      @change="updateCampaign"
                      :rules="requiredRules"
                      hide-details="auto"
                      class="hide-number-controls font-size-15"
                      id="campaignQuantityOne-input"
                      type="number"
                  ></v-text-field>
                </v-col>
              </template>

              <v-col cols="12" md="6" v-if="
                  campaign.type === 'buyTogether'
                  || campaign.type === 'alexaBestSeller'
                  || campaign.type === 'alexaAmazonChoice'
              ">
                <Label forInput="campaignAsinTwo-input">ASIN 2:</Label>
                <v-text-field
                    v-model.trim="campaign.data.asinTwo"
                    outlined
                    persistent-placeholder
                    placeholder="Enter ASIN 2"
                    dense
                    flat
                    @change="updateCampaign"
                    :rules="campaign.type === 'alexaBestSeller' || campaign.type === 'alexaAmazonChoice' ? [] : [...requiredRules, ...asinRules]"
                    hide-details="auto"
                    class="font-size-15"
                    id="campaignAsinTwo-input"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" v-if="campaign.type === 'buyTogether'">
                <Label forInput="campaignQuantityTwo-input">Quantity 2:</Label>
                <v-text-field
                    v-model.number="campaign.data.quantityTwo"
                    outlined
                    persistent-placeholder
                    placeholder="Enter Quantity 2"
                    dense
                    @change="updateCampaign"
                    flat
                    :rules="requiredRules"
                    hide-details="auto"
                    class="hide-number-controls font-size-15"
                    id="campaignQuantityTwo-input"
                    type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" v-if="
                  campaign.type === 'buyTogether'
                  || campaign.type === 'alexaBestSeller'
                  || campaign.type === 'alexaAmazonChoice'
              ">
                <Label forInput="campaignAsinThree-input">ASIN 3:</Label>
                <v-text-field
                    v-model.trim="campaign.data.asinThree"
                    outlined
                    persistent-placeholder
                    placeholder="Enter ASIN 3"
                    dense
                    @change="updateCampaign"
                    flat
                    :rules="
                      campaign.type === 'alexaBestSeller' || campaign.type === 'alexaAmazonChoice' || !campaign.data.quantityThree
                        ? []
                        : [...requiredRules, ...asinRules]
                    "
                    hide-details="auto"
                    class="font-size-15"
                    id="campaignAsinThree-input"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" v-if="campaign.type === 'buyTogether'">
                <Label forInput="campaignQuantityThree-input">Quantity 3:</Label>
                <v-text-field
                    v-model.number="campaign.data.quantityThree"
                    outlined
                    persistent-placeholder
                    placeholder="Enter Quantity 3"
                    dense
                    @change="updateCampaign"
                    flat
                    :rules="campaign.data.asinThree ? requiredRules : []"
                    hide-details="auto"
                    class="hide-number-controls font-size-15"
                    id="campaignQuantityThree-input"
                    type="number"
                ></v-text-field>
              </v-col>

              <template v-if="campaign.type === 'buyTogether'">
                <v-col cols="12" md="6">
                  <Label forInput="campaignAsinFour-input">ASIN 4:</Label>
                  <v-text-field
                      v-model.trim="campaign.data.asinFour"
                      outlined
                      persistent-placeholder
                      placeholder="Enter ASIN 4"
                      dense
                      @change="updateCampaign"
                      flat
                      :rules="campaign.data.quantityFour ? [...requiredRules, ...asinRules] : []"
                      hide-details="auto"
                      class="font-size-15"
                      id="campaignAsinFour-input"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <Label forInput="campaignQuantityFour-input">Quantity 4:</Label>
                  <v-text-field
                      v-model.number="campaign.data.quantityFour"
                      outlined
                      persistent-placeholder
                      placeholder="Enter Quantity 4"
                      dense
                      @change="updateCampaign"
                      flat
                      :rules="campaign.data.asinFour ? requiredRules : []"
                      hide-details="auto"
                      class="hide-number-controls font-size-15"
                      id="campaignQuantityFour-input"
                      type="number"
                  ></v-text-field>
                </v-col>
              </template>

              <template v-if="campaign.type === 'canonical'">
                <v-col cols="12" md="6">
                  <Label forInput="campaignCanonicalKeywordOne-input">Keyword 1:</Label>
                  <v-text-field
                      v-model.trim="campaign.data.keywordOne"
                      outlined
                      persistent-placeholder
                      placeholder="Enter Keyword 1"
                      dense
                      @change="updateCampaign"
                      flat
                      :rules="requiredRules"
                      hide-details="auto"
                      class="font-size-15"
                      id="campaignCanonicalKeywordOne-input"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <Label forInput="campaignCanonicalKeywordTwo-input">Keyword 2:</Label>
                  <v-text-field
                      v-model.trim="campaign.data.keywordTwo"
                      outlined
                      persistent-placeholder
                      placeholder="Enter Keyword 2"
                      dense
                      flat
                      @change="updateCampaign"
                      :rules="requiredRules"
                      hide-details="auto"
                      class="font-size-15"
                      id="campaignCanonicalKeywordTwo-input"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <Label forInput="campaignCanonicalKeywordThree-input">Keyword 3:</Label>
                  <v-text-field
                      v-model.trim="campaign.data.keywordThree"
                      outlined
                      @change="updateCampaign"
                      persistent-placeholder
                      placeholder="Enter Keyword 3"
                      dense
                      flat
                      hide-details="auto"
                      class="font-size-15"
                      id="campaignCanonicalKeywordThree-input"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <Label forInput="campaignCanonicalKeywordFour-input">Keyword 4:</Label>
                  <v-text-field
                      v-model.trim="campaign.data.keywordFour"
                      outlined
                      persistent-placeholder
                      placeholder="Enter Keyword 4"
                      dense
                      @change="updateCampaign"
                      flat
                      hide-details="auto"
                      class="font-size-15"
                      id="campaignCanonicalKeywordFour-input"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <Label forInput="campaignCanonicalKeywordFive-input">Keyword 5:</Label>
                  <v-text-field
                      v-model.trim="campaign.data.keywordFive"
                      outlined
                      persistent-placeholder
                      placeholder="Enter Keyword 5"
                      dense
                      @change="updateCampaign"
                      flat
                      hide-details="auto"
                      class="font-size-15"
                      id="campaignCanonicalKeywordFive-input"
                  ></v-text-field>
                </v-col>
              </template>

              <v-col cols="12" md="6" v-if="campaign.type === 'walmartViaSeller'">
                <Label forInput="campaignStore-input">Store Name:</Label>
                <v-text-field
                    v-model.trim="campaign.data.store"
                    outlined
                    persistent-placeholder
                    placeholder="Enter Store"
                    dense
                    @change="updateCampaign"
                    flat
                    :rules="requiredRules"
                    hide-details="auto"
                    class="font-size-15"
                    id="campaignStore-input"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="campaign.type === 'walmartDirect'" md="6">
                <Label forInput="campaignItemId-input">Item ID:</Label>
                <v-text-field
                    v-model.trim="campaign.data.itemId"
                    outlined
                    placeholder="Enter Item ID"
                    persistent-placeholder
                    @change="updateCampaign"
                    dense
                    flat
                    :rules="[...requiredRules, ...itemIdRules]"
                    hide-details="auto"
                    id="campaignItemId-input"
                    class="font-size-15"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3">
          <v-card outlined class="pa-5 fill-height">
            <Label>Google Rotation:</Label>

            <div>
              <v-checkbox color="cyan accent-4" @change="updateCampaign" dense class="mt-0 font-size-14" hide-details label="URL Rotation" v-model="campaign.grUrlRotation"></v-checkbox>
              <v-checkbox color="cyan accent-4" @change="updateCampaign" :disabled="!campaign.grUrlRotation" dense class="mt-0 font-size-14" hide-details label="Additional PPC Parameters" v-model="campaign.ppcRedirect"></v-checkbox>
            </div>

            <div class="mt-4" v-show="false">
              <Label style="margin-bottom: 0.5rem">Redirect Generation Type:</Label>

              <div>
                <v-btn-toggle
                    v-model="campaign.grUrlGenerationType"
                    color="cyan accent-4"
                    dense
                    class="d-flex justify-center btn-toggle--active-outline"
                    @change="updateCampaign"
                >
                  <v-btn :disabled="!campaign.grUrlRotation" class="custom--btn flex-grow-1" value="redirectGenerator" outlined small>
                    Google Search
                  </v-btn>

                  <v-btn :disabled="!campaign.grUrlRotation" class="custom--btn flex-grow-1" value="hangouts" outlined small>
                    Google Hangouts
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>

            <div v-if="campaign.type !== 'walmartViaBrand' && campaign.type !== 'walmartViaSeller' && campaign.type !== 'walmartDirect'"
            >
              <v-divider class="mt-3 mb-2"></v-divider>
              <div class="d-flex align-center">
                <v-checkbox color="cyan accent-4" @change="updateCampaign" dense class="mt-0 font-size-14 pt-0" hide-details label="Force Amazon App" v-model="campaign.forceAmazonApp" :disabled="campaign.pixels.length > 0"></v-checkbox>
                <v-tooltip bottom v-if="campaign.pixels.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small right class="ml-2 position-relative" style="top: 1px"
                    >mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>You cannot use the "Force Amazon App" along with pixels</span>
                </v-tooltip>
              </div>
            </div>

            <div class="mt-4">
              <div class="d-flex align-center mb-1">
                <Label class="mb-0" forInput="campaignMaas-input">Custom MAAS:</Label>
                <v-popover placement="right" popoverBaseClass="v-tooltip__content tooltip popover" container="#app" trigger="hover">
                  <v-icon
                      class="ml-2 mb-1"
                      small
                  >
                    mdi-help-circle
                  </v-icon>

                  <template slot="popover">
                    <span>Enter custom MAAS or leave the field empty to generate a new one.</span>
                  </template>
                </v-popover>
              </div>
              <v-popover class="popover--width" placement="left" popoverBaseClass="v-tooltip__content tooltip popover" container="#app" trigger="hover">
                <v-text-field
                    v-model.trim="campaign.maas"
                    outlined
                    persistent-placeholder
                    placeholder="Enter MAAS"
                    dense
                    small
                    flat
                    @change="updateCampaign"
                    required
                    hide-details="auto"
                    :readonly="!campaign.grUrlRotation"
                    class="input--height-28 font-size-12"
                    id="campaignMaas-input"
                    :class="{'grey--text': !campaign.grUrlRotation}"
                >
                </v-text-field>

                <template slot="popover">
                  <span>{{!campaign.grUrlRotation ? 'Enable URL Rotation to edit' : 'Enter your maas' }}</span>
                </template>
              </v-popover>
            </div>

          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="9">
          <v-card outlined class="pa-5">
           <v-row align="end" class="custom--gutters">
             <v-col>
               <div class="mb-1 d-flex justify-space-between align-center">
                 <Label class="mb-0" for-input="campaignPixels-select">Select pixels from the list:</Label>
                 <v-tooltip bottom v-if="campaign.forceAmazonApp">
                   <template v-slot:activator="{ on, attrs }">
                     <v-icon
                         v-bind="attrs"
                         v-on="on"
                         small
                     >mdi-alert-circle
                     </v-icon>
                   </template>
                   <span>You cannot use pixels along with the "Force Amazon App"</span>
                 </v-tooltip>
               </div>
               <v-select
                   :items="pixels"
                   v-model="campaign.pixels"
                   multiple
                   chips
                   deletable-chips
                   @change="updateCampaign"
                   placeholder="Select Pixels"
                   persistent-placeholder
                   outlined
                   dense
                   flat
                   hide-details="auto"
                   id="campaignPixels-select"
                   item-color="cyan accent-4"
                   class="multiple-select-with-chips-remove-left-padding"
                   :disabled="campaign.forceAmazonApp"
               >
                 <template v-slot:item="{ parent, item, on, attrs }">
                   <v-list-item-action class="mr-3">
                     <v-simple-checkbox v-on="on"  v-bind="attrs" :ripple="false" :value="attrs['aria-selected'] == 'true'"></v-simple-checkbox>
                   </v-list-item-action>

                   <v-list-item-content>
                     <v-list-item-title>{{item.text}}</v-list-item-title>
                   </v-list-item-content>

                   <v-list-item-action>
                     <v-popover placement="left" popoverBaseClass="v-tooltip__content tooltip popover" container="#app" trigger="hover">
                       <v-icon color="cyan accent-4" @click.stop="deletePixel.pixelToDelete = item; deletePixel.showModal = true"

                       >
                         mdi-trash-can-outline
                       </v-icon>

                       <template slot="popover">
                         <span>Delete Pixel</span>
                       </template>
                     </v-popover>
                   </v-list-item-action>
                 </template>
               </v-select>
             </v-col>
             <v-col cols="12" md="auto" >
               <v-btn block depressed height="40" dark color="cyan accent-4" class="custom--btn" :class="{'disabled-opacity pointer-events-none': campaign.forceAmazonApp}" @click="campaign.forceAmazonApp ? showPixelModal = false : showPixelModal = true">
                 Create new
                 <v-icon
                     right
                 >
                   mdi-plus
                 </v-icon>
               </v-btn>
             </v-col>
           </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" v-if="campaign">
          <v-card outlined class="pa-5 fill-height">
            <div>
              <Label>URL:</Label>
              <RotatorFinalUrl :campaign="campaign" />
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="settings.showKeywordsTable">
        <v-col cols="12" lg="9">
          <v-card class="fill-height overflow-hidden" outlined>
            <v-simple-table class="table--sm table--row-62 table-fill-height rotator__keywords-table fill-height" fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left keyword-col">
                    Keyword
                  </th>
                  <th class="text-center distribution-col">
                    Distribution
                  </th>
                  <th class="controls-col">

                  </th>
                </tr>
                </thead>
                <tbody>
                  <template v-if="campaignKeywordsLength">
                    <tr
                        v-for="(keyword, index) in Object.keys(campaign.keywordPriorities)"
                        :key="keyword"
                        :class="{'archived-keyword-row': campaign.keywordPriorities[keyword].archived}"
                    >
                      <td class="keyword-col">
                        <div class="text-truncate">{{ keyword }}</div>
                      </td>
                      <td class="distribution-col text-center">
                        <template v-if="campaign.keywordPriorities[keyword].archived">--</template>
                        <template v-else>
                          <div v-if="campaign.rotationType === 'random'">
                            <v-text-field
                                v-model.number="campaign.keywordPriorities[keyword].priority"
                                outlined
                                persistent-placeholder
                                dense
                                small
                                flat
                                required
                                hide-details="auto"
                                type="number"
                                class="input--height-28 font-size-12 hide-number-controls input--text-center ma-auto keyword-priority-input"
                                :class="{'error--text': !campaign.keywordPriorities[keyword].priority}"
                                min="0"
                                max="100"
                                style="width: 78px"
                                @change="updateCampaign"
                            >
                              <template v-slot:append-outer>
                                <span class="font-size-12" style="margin-top: 2px;">%</span>
                              </template>
                            </v-text-field>
                          </div>
                          <div v-else>
                            {{index + 1}}
                          </div>
                        </template>
                      </td>
                      <td class="text-right controls-col d-flex align-center justify-end" :key="archivedCounter">
                        <v-btn class="custom--btn mr-2" style="min-width: 93px" color="cyan accent-4" outlined depressed small @click="archiveKeyword(keyword)">
                          <v-icon small left>
                            {{campaign.keywordPriorities[keyword].archived ? 'mdi-archive-check-outline' : 'mdi-archive-cancel-outline'}}
                          </v-icon>
                          {{campaign.keywordPriorities[keyword].archived ? 'Resume' : 'Pause'}}
                        </v-btn>

                        <v-btn class="custom--btn" style="min-width: 93px" color="cyan accent-4" outlined depressed small @click="deleteKeyword.keywordToDelete = keyword; deleteKeyword.showModal = true">
                          <v-icon small left>
                            mdi-trash-can-outline
                          </v-icon>
                          Delete
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr key="no-keywords-row">
                      <td class="keyword-col" colspan="3">
                        <span class="text--secondary">No Keywords Found</span>
                      </td>
                    </tr>
                  </template>

                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3">
          <v-card outlined class="pa-6 fill-height">
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model.trim="addKeyword"
                    outlined
                    persistent-placeholder
                    placeholder="Enter Keyword"
                    dense
                    small
                    flat
                    required
                    hide-details="auto"
                    :error-messages="errors.addKeyword"
                    @keypress="handleAddKeywordsKeyPress"
                    class="font-size-15"
                    @input="addKeyword = addKeyword.toLowerCase()"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="auto">
                <v-btn block color="cyan accent-4" class="custom--btn" outlined depressed height="40" @click="addNewKeyword">
                  Add
                </v-btn>
              </v-col>
            </v-row>

            <div class="mt-4">
              <Label style="margin-bottom: 0.5rem">Type:</Label>
              <v-btn-toggle
                  v-model="campaign.rotationType"
                  color="cyan accent-4"
                  dense
                  class="d-flex justify-center btn-toggle--active-outline"
                  @change="updateCampaign"
              >
                <v-btn class="custom--btn flex-grow-1" value="sequence" outlined small>
                  Sequence
                </v-btn>

                <v-btn class="custom--btn flex-grow-1" value="random" outlined small>
                  Random
                </v-btn>
              </v-btn-toggle>
            </div>

            <template>
              <v-divider class="mt-6 mb-4"></v-divider>

              <v-row align="center" class="text-h6">
                <v-col><span>Total:</span></v-col>
                <v-col cols="auto">
                  <span v-if="campaign.rotationType === 'random' && campaignKeywordsLength">
                    {{totalDistribution ? totalDistribution.toFixed(1) : 0}}%
                  </span>
                  <span v-else>—</span>
                </v-col>
              </v-row>

              <v-alert
                  dense
                  text
                  class="align-icon-center mt-3 custom-alert-bg--grey mb-0 pa-5"
              >
                <div class="d-flex align-center">
                  <v-icon color="cyan accent-4" >mdi-information</v-icon>
                  <v-divider
                      vertical
                      class="mx-5"
                  ></v-divider>
                  <div class="text-body-2">
                    {{
                      campaign.rotationType === 'random'
                          ? campaignKeywordsLengthWithoutArchived
                              ? 'Total should be 100% or we will be assuming that distribution between the keywords is equal'
                              : 'Add keyword(s) to campaign'
                          : 'With every redirect the next keyword on the list will be chosen. Keywords will be rotating one after another.'
                    }}
                  </div>
                </div>
              </v-alert>
            </template>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="showMultipleKeywordsTable">
        <v-col>
          <v-card outlined class="pa-5">
            <MultipleKeywordsTable :campaign="campaign || {}" :onMultipleKeywordsChanged="onMultipleKeywordsChanged" />
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card outlined class="py-3 px-6">
            <v-row align="center">
              <v-col class="pb-sm-3 pb-0 text-center text-sm-left">
                <span class="text-body-2">All of the settings and statistics will be deleted permanently.</span>
              </v-col>

              <v-col cols="12" sm="auto" class="text-right">
                <v-btn color="red accent-4" class="custom--btn" block outlined depressed @click="deleteCampaign.showModal = true" height="40">
                  Delete Campaign
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
          v-model="showPixelModal"
          width="380"
      >
        <Loader :show="createPixel.loading" :size="24" :absolute="false" />

        <v-card outlined >
          <v-card-title class="text-h6 px-7 py-6 grey lighten-4">
            <div class="position-relative width-100 ">
              <span class="d-block pr-6">Create Pixel</span>
              <v-icon @click="showPixelModal = false" small class="cursor-pointer absolute-right absolute-vertical-center position-absolute">mdi-close</v-icon>
            </div>
          </v-card-title>

          <v-form ref="createPixelForm" v-model="createPixel.valid" @submit.prevent="handleAddPixelSubmit" class="px-7 pa-6">
            <div>
              <Label forInput="addPixel-name">Pixel Name:</Label>
              <v-text-field
                  v-model.trim="createPixel.name"
                  outlined
                  persistent-placeholder
                  placeholder="Enter Name"
                  dense
                  flat
                  required
                  hide-details="auto"
                  id="addPixel-name"
                  :rules="requiredRules"
                  class="font-size-15"
                  :error-messages="createPixel.errors.name"
              ></v-text-field>
            </div>

            <div class="mt-4">
              <Label for-input="addPixel-type">Pixel Type:</Label>
              <v-select
                  :items="pixelTypes"
                  v-model="createPixel.type"
                  placeholder="Select Pixels"
                  persistent-placeholder
                  outlined
                  dense
                  flat
                  hide-details="auto"
                  id="addPixel-type"
                  class="font-size-15"
                  :error-messages="createPixel.errors.type"
              >

              </v-select>
            </div>

            <div class="mt-4">
              <Label forInput="addPixel-id">Pixel ID:</Label>
              <v-text-field
                  v-model.trim="createPixel.id"
                  outlined
                  persistent-placeholder
                  placeholder="Enter ID"
                  dense
                  flat
                  required
                  hide-details="auto"
                  :rules="requiredRules"
                  id="addPixel-id"
                  class="font-size-15"
                  :error-messages="createPixel.errors.trackId"
              ></v-text-field>
            </div>

            <v-row class="mt-3">
              <v-col>
                <v-btn
                    class="custom--btn" outlined depressed color="cyan accent-4" @click="showPixelModal = false" block height="40"
                >
                  Cancel
                </v-btn>
              </v-col>

              <v-col>
                <v-btn
                    class="custom--btn" dark depressed color="cyan accent-4" block type="submit" height="40"
                >
                  Add
                </v-btn>
              </v-col>
            </v-row>

          </v-form>
        </v-card>
      </v-dialog>

      <ConfirmModal
          title="Delete Pixel"
          :show.sync="deletePixel.showModal"
          @onConfirm="onDeletePixel"
          @onClose="deletePixel.showModal = false; deletePixel.pixelToDelete = null;"
      >
        <v-card-text class="pa-0" v-if="deletePixel.pixelToDelete">
          <p class="mb-1">Delete Pixel?</p>
          <p class="mb-0">
            <span class="text--primary font-weight-medium">Name:</span> {{deletePixel.pixelToDelete.text}}
          </p>
        </v-card-text>
      </ConfirmModal>

      <ConfirmModal
          title="Delete Keyword"
          :show.sync="deleteKeyword.showModal"
          @onConfirm="onDeleteKeyword"
          @onClose="deleteKeyword.showModal = false; deleteKeyword.keywordToDelete = null;"
      >
        <v-card-text class="pa-0" v-if="deleteKeyword.keywordToDelete">
          <p class="mb-1">Delete Keyword <b>{{deleteKeyword.keywordToDelete.title}}</b>?</p>
        </v-card-text>
      </ConfirmModal>

      <ConfirmModal
          title="Delete Campaign"
          :show.sync="deleteCampaign.showModal"
          @onConfirm="onDeleteCampaign(campaign._id)"
          @onClose="deleteCampaign.showModal = false;"
      >
        <v-card-text class="pa-0" v-if="campaign">
          <p class="mb-0">
            <span class="text--primary font-weight-medium">Name:</span> {{campaign.name}}
          </p>
          <p class="mb-0" v-if="rotatorTypes[campaign.type]">
            <span class="text--primary font-weight-medium">Type:</span> {{rotatorTypes[campaign.type].text}}
          </p>
          <p class="mb-0" v-if="campaign.asin">
            <span class="text--primary font-weight-medium">ASIN:</span> {{campaign.asin}}
          </p>
        </v-card-text>
      </ConfirmModal>

      <QrCodeModal />
    </v-form>
  </v-container>
</template>

<script>

import {mapGetters, mapMutations, mapState} from 'vuex';
import rotatorApi from '../api/rotator';
import domainsApi from '../api/domains';
import Label from '../components/Label';
import Loader from '../components/Loader';
import QrCodeModal from '../components/QrCodeModal';
import RotatorFinalUrl from '../components/RotatorFinalUrl';
import WalmartMarketplaceOption from '../components/WalmartMarketplaceOption';
import ConfirmModal from '../components/ConfirmModal';
import MultipleKeywordsTable from '../components/MultipleKeywordsTable';
import {requiredRules, asinRules, itemIdRules} from '../js/validators';
import { showNotification } from '../js/helpers';
import {getCampaignDomain, getKeywordDistributionByPriority, setCampaignUrl} from '../js/rotator';
import { mapCampaign } from '../js/rotator';
import config from '../../config';

export default {
  name: 'RotatorEdit',

  computed: {
    ...mapState(['user']),
    ...mapState(['dataByMarketplaceId']),
    ...mapState('rotator', [
      'keywordDataByCampaign', 'campaignsStatistics'
    ]),
    ...mapGetters('rotator', {
      pixels: 'getPixels',
      marketplaceOptions: 'getMarketplacesOptions',
      rotatorTypes: 'getAvailableRotatorTypes'
    }),
    ...mapGetters('domains', ['verifiedDomainsAndDefault', 'defaultDomain', 'domainsDataById', 'defaultSystemDomain']),
    campaignKeywordsLength() {
      if (!this.campaign) { return }
      return Object.values(this.campaign.keywordPriorities).length;
    },
    campaignKeywordsLengthWithoutArchived() {
      if (!this.campaign) { return }
      return Object.values(this.campaign.keywordPriorities).filter(_keywordData => !_keywordData.archived).length;
    },
    totalDistribution() {
      const { archivedCounter } = this;

      return Object.keys(this.campaign.keywordPriorities).reduce((total, _keyword) => {
        const { priority = 0, archived = false } = this.campaign.keywordPriorities[_keyword];

        if (!priority || archived) { return total }
        return total += priority;
      }, 0);
    },
    isAllKeywordsEqualDistributionInPercentage() {
      let isAllKeywordsEqual = true, prevKeywordPriority;
      const { keywordPriorities = {} } = this.campaign;

      const keywords = Object.values(keywordPriorities).filter(_keywordData => !_keywordData.archived);
      keywords.forEach((_keyword, i) => {
        const { priority = 0 } = _keyword;

        if (priority !== prevKeywordPriority && i !== 0) {
          isAllKeywordsEqual = false;
        }

        prevKeywordPriority = priority;
      });

      return isAllKeywordsEqual;
    },
    canUpdate() {
      const updateObj = {
        valid: false,
        errors: {}
      };

      const isKeywordsTableCampaignWithRandomRotation = this.settings.showKeywordsTable && this.campaignKeywordsLengthWithoutArchived && this.campaign.rotationType === 'random';

      const distributionError = ((100 - this.totalDistribution) / 100) * 100; // error in %
      if (isKeywordsTableCampaignWithRandomRotation && distributionError > 1) {
        updateObj.errors['totalOfKeywordPriorities'] = 'Total distribution must be 100%';
      }

      if (isKeywordsTableCampaignWithRandomRotation && Object.values(this.campaign.keywordPriorities).some(_keywordData => !_keywordData.priority)) {
        updateObj.errors['keywordPriorities'] = 'Each keyword distribution should be greater than 0%';
      }

      updateObj.valid = Object.keys(updateObj.errors).length === 0;

      return updateObj;
    },
    settings() {
      if (!this.rotatorTypes) { return {} }
      return this.rotatorTypes[this.campaign.type] || {};
    },
    showMultipleKeywordsTable() {
      if (!this.campaign) { return false }
      return this.typesForMultipleKeywords.has(this.campaign.type);
    }
  },

  components: {
    Label, ConfirmModal, WalmartMarketplaceOption,
    RotatorFinalUrl, QrCodeModal, Loader, MultipleKeywordsTable
  },

  watch: {
    campaign: {
      deep: true,
      handler: function(val) {
        if (val) {
          this.$nextTick(function() {
            const isValidate = this.$route?.query?.validate;
            if (isValidate && this.$refs.form) {
              this.$refs.form.validate();
            }
          });
        }
      }
    },
    showPixelModal() {
      this.handleAddPixelModalChangeVisibility();
    }
  },

  methods: {
    ...mapMutations(['setLoading']),
    onMultipleKeywordsChanged(multipleKeywords) {
      if (!Array.isArray(multipleKeywords)) { return }
      this.campaign.multipleKeywords = multipleKeywords;

      this.$nextTick(function() {
        this.updateCampaign();
      });
    },
    resetCreatePixelErrors() {
      Object.keys(this.createPixel.errors).forEach(error => this.createPixel.errors[error] = '');
    },
    archiveKeyword(keyword) {
      const { archived = false } = this.campaign.keywordPriorities[keyword];
      this.campaign.keywordPriorities[keyword].archived = !archived;

      this.archivedCounter++;
      this.getKeywordsPercentageAndUpdateExistedIfPossible(true);

      this.$nextTick(function() {
        this.updateCampaign();
      });
    },
    disableMarketplaceOption(option) {
      const { type } = this.campaign;
      const { value } = option;

      return (type === 'alexaAmazonChoice' || type === 'alexaBestSeller') && (
          value === 'A2VIGQ35RCS4UG' || value === 'A2Q3Y263D00KWC' || value === 'ARBP9OOSHTCHU' || value === 'A1805IZSGTT6HS'
          || value === 'A1C3SOZRARQ6R3' || value === 'A17E79C6D8DWNP' || value === 'A2NODRKZP88ZB9' || value === 'A33AVAJ2PDY3EV'
          || value === 'A19VAU5U5O7RUS'
      );
    },
    async updateCampaign() {
      if (this.$refs.form) {
        this.$refs.form.validate();
      }

      if (!this.canUpdate.valid || !this.valid) {
        const { errors } = this.canUpdate;

        Object.values(errors).forEach(error => {
          showNotification({ type: 'error', title: error });
        });

        this.$nextTick(function() {
          const erroredInput = document.querySelector('.keyword-priority-input.error--text');
          if (erroredInput) {
            erroredInput.scrollIntoView();
          }
        });

        return;
      }

      const {
        _id, keywordPriorities = {}, pixels, market, grUrlRotation = false, ppcRedirect = false,
        rotationType = 'random', type, data, name, asin, maas: campaignMaas, customDomain, grUrlGenerationType,
        multipleKeywords = [], itemId, forceAmazonApp = false
      } = this.campaign;

      const campaignToUpdate = {
        campaignId: _id,
        market: type === 'walmartVieBrand' || type === 'walmartVieSeller' ? 'ATVPDKIKX0DER' : market,
        keywordPriorities: JSON.stringify(keywordPriorities),
        pixels: JSON.stringify(pixels),
        ppcRedirect,
        grUrlRotation,
        rotationType,
        name,
        data: JSON.stringify(data),
        grUrlGenerationType: 'redirectGenerator',
        multipleKeywords: JSON.stringify(multipleKeywords),
        forceAmazonApp
      };

      if (asin) { campaignToUpdate.asin = asin }
      if (customDomain) { campaignToUpdate.customDomain = customDomain }
      if ((campaignMaas || typeof campaignMaas === 'string') && grUrlRotation) { campaignToUpdate.maas = campaignMaas }

      const { status, message, errors = {}, maas } = await rotatorApi.updateCampaign(campaignToUpdate);
      const errorsKeys = Object.keys(errors);

      if (!status) {
        if (errorsKeys.length === 0) {
          showNotification({ type: 'error', title: message });
        }

        errorsKeys.forEach(error => {
          const errorMessage = this.mapFieldAndMessage[error] || error;
          showNotification({ type: 'error', title: errorMessage });
        });

        return;
      }

      if (maas && this.campaign.mass !== maas) {
        this.campaign.maas = maas;
      }

      showNotification({ title: 'Campaign Updated!' });
    },
    async onDeleteCampaign(campaignId) {
      this.deleteCampaign.showModal = false;
      this.setLoading(true);

      const { status } = await rotatorApi.deleteCampaign(campaignId);
      this.setLoading(false);

      if (!status) { return }

      showNotification({ title: 'Campaign Deleted!' });
      this.$router.push('/rotator');
    },
    onDeleteKeyword() {
      this.deleteKeyword.showModal = false;

      const { keywordToDelete } = this.deleteKeyword;
      if (!keywordToDelete) { return }

      const updatedPriorities = {...this.campaign.keywordPriorities};
      delete updatedPriorities[keywordToDelete];

      this.campaign.keywordPriorities = updatedPriorities;

      this.$nextTick(function() {
        this.getKeywordsPercentageAndUpdateExistedIfPossible(true);

        this.updateCampaign();
      });
    },
    handleAddKeywordsKeyPress(e) {
      if (e.charCode === 13) {
        e.preventDefault();
        this.addNewKeyword();
      }
    },
    setKeywordsDistribution(value) {
      Object.keys(this.campaign.keywordPriorities).forEach(_keyword => {
        if (this.campaign.keywordPriorities[_keyword].archived) { return }
        this.campaign.keywordPriorities[_keyword].priority = value;
      });
    },
    getKeywordsPercentageAndUpdateExistedIfPossible(isFromDelete = false) {
      const keywordValueToAdd = this.getKeywordPercentage(isFromDelete);
      const { isAllKeywordsEqualDistributionInPercentage } = this;

      if (isAllKeywordsEqualDistributionInPercentage && keywordValueToAdd) {
        this.setKeywordsDistribution(keywordValueToAdd);
      }

      return keywordValueToAdd;
    },
    addNewKeyword() {
      if (this.addKeyword === '') { return this.errors.addKeyword = 'This field is required.' }

      const keywordToAdd = this.addKeyword;

      const isKeywordAlreadyAdded = Object.keys(this.campaign.keywordPriorities).find(_keyword => _keyword === keywordToAdd);
      if (isKeywordAlreadyAdded) { return this.errors.addKeyword = 'This field is already added.' }

      const keywordValueToAdd = this.getKeywordsPercentageAndUpdateExistedIfPossible(false);

      this.$set(this.campaign.keywordPriorities, keywordToAdd, { priority: keywordValueToAdd, archived: false });

      this.addKeyword = '';
      this.errors.addKeyword = '';

      this.updateCampaign();
    },
    getKeywordPercentage(isFromDelete = false) {
      const { isAllKeywordsEqualDistributionInPercentage, totalDistribution } = this;
      const keywords = Object.values(this.campaign.keywordPriorities).filter(_keywordData => !_keywordData.archived);

      const nextKeywordsAmount = isFromDelete ? keywords.length : keywords.length + 1;

      let keywordValueToAdd = isAllKeywordsEqualDistributionInPercentage
          ? 100 / nextKeywordsAmount
          : totalDistribution < 100 ? 100 - totalDistribution : 0;

      if (!keywordValueToAdd) { keywordValueToAdd = 0 }

      return keywordValueToAdd;
    },
    async onDeletePixel() {
      this.setLoading(true);

      this.deletePixel.showModal = false;

      const { pixelToDelete } = this.deletePixel;
      if (!pixelToDelete) { return }

      const { status } = await rotatorApi.deletePixel(pixelToDelete.id);
      this.setLoading(false);

      if (!status) { return }
      showNotification({ title: 'Pixel Deleted!' });
    },
    handleAddPixelModalChangeVisibility(e) {
      if (this.showPixelModal) { return }

      this.createPixel.type = 'facebook';
      this.createPixel.name = '';
      this.createPixel.id = '';
      this.createPixel.loading = false;

      if (this.$refs.createPixelForm) {
        this.resetCreatePixelErrors();
        this.$refs.createPixelForm.resetValidation();
      }
    },
    async handleAddPixelSubmit() {
      this.resetCreatePixelErrors();

      if (!this.$refs.createPixelForm.validate() || !this.createPixel.valid) { return }

      const newPixel = {
        name: this.createPixel.name,
        type: this.createPixel.type,
        trackId: this.createPixel.id
      };

      this.createPixel.loading = true;
      const { status, pixel } = await rotatorApi.addPixel.call(this.createPixel, newPixel);
      this.createPixel.loading = false;

      this.$nextTick(function() {
        if (!status || !pixel) { return }

        this.showPixelModal = false;
        showNotification({ title: 'Pixel Created!' });
      });
    },
  },

  async beforeMount() {
    const campaignId = this.$route.params.id;
    if (!campaignId) { return this.$router.push('/rotator') }

    this.setLoading(true);

    const response = await rotatorApi.getCampaignData(campaignId);
    if (!response || !response.status) { return this.$router.push('/rotator') }

    await domainsApi.getDomains();

    const campaign = {
      ...mapCampaign(response.campaign, this.keywordDataByCampaign, this.campaignsStatistics),
      customDomain: this.getCampaignDomain(response.campaign, this.defaultDomain)
    };

    const { defaultDomain, domainsDataById, defaultSystemDomain } = this;
    this.campaign = setCampaignUrl(campaign, { defaultDomain, domainsDataById, defaultSystemDomain });

    this.$nextTick(function() {
      this.setLoading(false);
    });
  },

  mounted() {
    this.requiredRules = requiredRules;
    this.asinRules = asinRules;
    this.itemIdRules = itemIdRules;
    this.getCampaignDomain = getCampaignDomain;
  },

  data: () => ({
    archivedCounter: 0,
    deleteCampaign: { showModal: false },
    deleteKeyword: { keywordToDelete: null, showModal: false },
    deletePixel: { pixelToDelete: null, showModal: false },
    mapFieldAndMessage: {
      'name': 'Campaign Name is required',
      'keywordPriorities': 'Keywords is required',
      'pixels': 'Pixels is required',
      'asin': 'ASIN is required',
      'market': 'Marketplace is required',
      'rotationType': 'Rotation Type is required',
      'totalOfKeywordPriorities': 'Total distribution must be 100%',
    },
    valid: false,
    errors: {
      addKeyword: ''
    },
    addKeyword: '',
    campaign: null,
    showPixelModal: false,
    createPixel: {
      type: 'facebook',
      name: '',
      id: '',
      valid: false,
      loading: false,
      errors: { name: '', type: '', trackId: '' }
    },
    pixelTypes: [
      { text: 'Facebook', value: 'facebook' },
      { text: 'Google Analytics', value: 'googleAnalytics' },
      { text: 'Google Tag Manager', value: 'googleTagManager' },
    ],
    typesForMultipleKeywords: new Set(['direct', 'directFullParams', 'search'])
  }),
}
</script>

<style lang="scss">
.rotator {
  &__keywords {
    &-table {
      .keyword-col {
        min-width: 450px;
        max-width: 450px;
      }
      .distribution-col {
        min-width: 240px;
        max-width: 240px;
      }
      .controls-col {
        //min-width: 180px;
        //max-width: 180px;
      }
    }
  }
}

.archived-keyword-row {
  .keyword-col, .distribution-col {
    & > * {
      opacity: .5;
    }
  }
  .keyword-col, .distribution-col, .controls-col {
    background: rgba(0, 0, 0, 0.03);
  }
}
</style>